<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container-fluid">
      <a class="navbar-brand" v-on:click="this.$router.push('/')">RefStats</a>
      <button class="navbar-toggler"
              type="button" data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        </ul>
        <div v-if="this.$router.currentRoute.value.path==='/login'">
          <div class="btn-group"
               v-if="this.$store.state.token==='null' || this.$store.state.token===''">
            <button type="button" class="btn btn-secondary"
                    v-on:click="this.$router.push('/refereeRanking');"
                    v-if="this.$router.currentRoute.value.path!=='/refereeRanking'">
              Referee Ranking</button>
            <button type="button"
                    class="btn btn-secondary" v-on:click="this.$router.go(-1)">Back</button>
            <button type="button" class="btn btn-secondary"
                    v-if="this.$store.state.token==='null' || this.$store.state.token===''"
                    v-on:click="this.$router.push('/register')" >Register</button>
          </div>
        </div>
        <div v-if="this.$router.currentRoute.value.path==='/register'">
          <div class="btn-group"
               v-if="this.$store.state.token==='null' || this.$store.state.token===''">
            <button type="button" class="btn btn-secondary"
                    v-on:click="this.$router.push('/refereeRanking');"
                    v-if="this.$router.currentRoute.value.path!=='/refereeRanking'">
              Referee Ranking</button>
            <button type="button"
                    class="btn btn-secondary" v-on:click="this.$router.go(-1)">Back</button>
            <button type="button" class="btn btn-secondary"
                    v-if="this.$store.state.token==='null' || this.$store.state.token===''"
                    v-on:click="this.$router.push('/login')" >Login</button>
          </div>
        </div>
        <div v-if="this.$router.currentRoute.value.path!=='/login' &&
        this.$router.currentRoute.value.path !=='/register'">
          <div class="btn-group"
               v-if="this.$store.state.token==='null' || this.$store.state.token===''">
            <button type="button" class="btn btn-secondary"
                    v-on:click="this.$router.push('/refereeRanking');"
                    v-if="this.$router.currentRoute.value.path!=='/refereeRanking'">
              Referee Ranking</button>
            <button type="button"
                    class="btn btn-secondary" v-on:click="this.$router.go(-1)">Back</button>
            <button type="button" class="btn btn-secondary"
                    v-if="this.$store.state.token==='null' || this.$store.state.token===''"
                    v-on:click="this.$router.push('/login')">LOGIN</button>
            <button type="button" class="btn btn-secondary"
                    v-if="this.$store.state.token==='null' || this.$store.state.token===''"
                    v-on:click="this.$router.push('/register')">Register</button>
          </div>
          <div class="btn-group"
               v-if="this.$store.state.token!=='null' && this.$store.state.token!==''">
            <button type="button" class="btn btn-secondary"
                    v-on:click="this.$router.push('/refereeRanking');"
                    v-if="this.$router.currentRoute.value.path!=='/refereeRanking'">
              Referee Ranking</button>
            <button type="button"
                    class="btn btn-secondary" v-on:click="this.$router.go(-1)">Back</button>
            <button type="button" class="btn btn-secondary"
                    v-on:click="this.$router.push('/profile/'+this.$store.state.user);">
              {{this.$store.state.user}}</button>
            <button type="button" class="btn btn-secondary"
                    v-on:click="logout">LOGOUT</button>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavBar',
  methods: {
    logout() {
      window.location.reload();
      this.$store.commit('setToken', null);
      this.$store.commit('setUser', null);
      this.$store.commit('setRefreshToken', null);
    },
  },
};
</script>

<style scoped>
.navbar-dark {
  margin-bottom: 3em;
}
.navbar-brand {
  font-family: 'Uni Sans Heavy', serif;
  font-size: 1.5em;
}
</style>
